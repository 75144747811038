import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['searchForm', 'searchBtn', 'input', 'clearBtn']

  connect () {
    useClickOutside(this, { element: this.searchBtnTarget })
    this.checkClearBtn()
  }

  clickOutside (e) {
    this.close()
  }

  open () {
    this.searchBtnTarget.classList.remove('closeSearch')
    this.searchBtnTarget.classList.add('search__form-active', 'openSearch')
    this.searchFormTarget.classList.remove('hidden')
    this.inputTarget.focus()
  }

  close () {
    if (this.inputTarget.value.trim() === '') {
      this.searchBtnTarget.classList.add('closeSearch')
      this.searchBtnTarget.classList.remove('openSearch')
      setTimeout(() => {
        this.searchBtnTarget.classList.remove('search__form-active')
        this.searchFormTarget.classList.add('hidden')
      }, 280)
    }
  }

  clearInput () {
    this.inputTarget.value = ''
    this.checkClearBtn()
  }

  checkClearBtn () {
    if (this.inputTarget.value.trim() === '') {
      this.clearBtnTarget.classList.add('opacity-0', 'invisible')
      this.clearBtnTarget.classList.remove('opacity-100')
    } else {
      this.clearBtnTarget.classList.remove('opacity-0', 'invisible')
      this.clearBtnTarget.classList.add('opacity-100')
    }
  }
}
