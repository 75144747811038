import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      onEnd: this.#end.bind(this),
      forceFallback: this.#isSafari(),
      fallbackClass: 'sortable-fallback'
    })
  }

  #end (event) {
    const url = this.data.get('url')
    const id = event.item.dataset.id

    const body = new FormData()
    body.append('position', event.newIndex)
    body.append('id', id)

    post(url, { body })
  }

  #isSafari () {
    return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
  }
}
