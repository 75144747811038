import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class InlineEditController extends Controller {
  static targets = ['dropdown', 'frame']

  connect () {
    this.toggleClass = this.data.get('class') || 'd_inline_edit__item-active'
  }

  disconnect () {
    this.clickOutside()
  }

  show () {
    this.element.classList.add(this.toggleClass)
    if (this.hasDropdownTarget) {
      useClickOutside(this, { element: this.dropdownTarget })
    }
  }

  clickOutside () {
    this.element.classList.remove(this.toggleClass)
    this.frameTarget.src = null
    if (this.hasDropdownTarget) {
      this.dropdownTarget.remove()
    }
  }
}
