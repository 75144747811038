import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  logout () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }

  logoutAs () {
    const cookieController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-relation="cookie"]'),
      'cookie'
    )

    if (cookieController) {
      const accId = cookieController.getCookie('_safari_overridden_by_account')
      cookieController.deleteCookie('_safari_overridden_by_account')
      cookieController.deleteCookie('_safari_overridden_user')

      window.location.href = `/acc/${accId}/dashboard/itineraries?logout_as=1`
    }
  }
}
