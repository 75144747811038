import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['links', 'template']

  connect () {
    this.wrapperClass = this.data.get('wrapperClass') || 'form__nested_fields'
  }

  addAssociation (event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation (event) {
    event.preventDefault()

    const wrapper = event.target.closest('.' + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
